<template>
  <div>
    <div class="page-header">
      <div class="page-header--title">
        <h1 class="title">Edition d'une catégorie</h1>
      </div>
    </div>

    <div class="page-content">
      <div class="card">
        <form action="" v-if="category">
          <div class="details">
            <div class="container">
              <b-field horizontal label="ID" v-if="category.uuid">
                  {{ category.uuid }}
                </b-field>

                <b-field horizontal label="Ajoutée le" v-if="category.uuid">
                  {{ category.created_at }}
                </b-field>

                <b-field horizontal label="Dernière modif." v-if="category.uuid">
                  {{ category.updated_at }}
                </b-field>

                <b-field horizontal label="Label">
                  <b-input type="text" placeholder="Label de la catégorie" required v-model="category.label"></b-input>
                </b-field>
              <b-field horizontal class="actions">
                <div class="is-pulled-right">
                  <!-- <b-button class="button is-danger" type="button" @click.prevent="deleteCategory">Supprimer</b-button> -->
                  <b-button class="button is-primary is-pulled-right" @click.prevent="updateCategory">Sauvegarder</b-button>
                </div>
              </b-field>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from '@/services/api.service'

export default {
  name: 'app-categories-modal',
  data () {
    return {
      category: null
    }
  },
  mounted () {
    this.getCategory()
  },
  methods: {
    getCategory () {
      http.get(`/faq/category/${this.$route.params.uuid}`).then(res => {
        this.category = res.data
      }).catch(err => {
        console.log(err)
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Nos serveurs rencontrent des difficultés à récupérer les informations concernant cette question',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      })
    },
    updateCategory () {
      http.put(`/faq/category/${this.category.uuid}`, { ...this.category }).then(res => {
        this.$buefy.notification.open({
          duration: 3000,
          message: 'La catégorie a été mise à jour avec succès !',
          position: 'is-bottom-left',
          type: 'is-success',
          hasIcon: true
        })
        this.$router.push({ path: '/app/questions/categories' })
      }).catch(err => {
        console.log(err)
        this.$buefy.notification.open({
          duration: 3500,
          message: 'Nos serveurs rencontrent des difficultés à mettre à jour cette categorie',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      })
    },
    deleteCategory () {
      console.log('delete')
    }
  }
}
</script>
